export default [
  {
    sys: {
      id: "1"
    },
    fields: {
      name: "Ga Xao Cari",
      code: "O1",
      slug: "ga-xao-cari",
      idCategory: "1",
      price: "139,00",
      isHot: 0,
      description: "Restovaná kuřecí prsíčka se kari, zeleninou, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "Ga La Que",
      code: "O2",
      slug: "ga-la-que",
      idCategory: "1",
      price: "129,00",
      isHot: 0,
      description: "Restovaná kuřecí prsíčka se zeleninou, chili a bazalkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "3"
    },
    fields: {
      name: "Ga Sa Ot",
      code: "O3",
      slug: "ga-sa-ot",
      idCategory: "1",
      price: "129,00",
      isHot: 0,
      description: "Smažená kuřecí stehna se zeleninou, citronovou trávou a chili.",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "4"
    },
    fields: {
      name: "Ga Chua Ngot",
      code: "O4",
      slug: "ga-chua-ngot",
      idCategory: "1",
      price: "129,00",
      isHot: 0,
      description: "Kuřecí prsíčka se zeleninou na sladko-kyselo",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "5"
    },
    fields: {
      name: "Vit Cari",
      code: "O5",
      slug: "vit-cari",
      idCategory: "1",
      price: "169,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou, červeným cari, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "6"
    },
    fields: {
      name: "Vit La Que",
      code: "O6",
      slug: "vit-la-que",
      idCategory: "1",
      price: "159,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou, chili a bazalkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "7"
    },
    fields: {
      name: "Vit Sa Ot",
      code: "O7",
      slug: "vit-sa-ot",
      idCategory: "1",
      price: "159,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou, citronovou trávou a chili",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "8"
    },
    fields: {
      name: "Vit Chua Ngot",
      code: "O8",
      slug: "vit-chua-ngot",
      idCategory: "1",
      price: "189,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou na sladko-kyselo",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "9"
    },
    fields: {
      name: "Bo La Que",
      code: "O9",
      slug: "bo-la-que",
      idCategory: "1",
      price: "109,00",
      isHot: 0,
      description: "Restované hovězí se zeleninou, chili a bazalkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "10"
    },
    fields: {
      name: "Bo Sa Ot",
      code: "O10",
      slug: "bo-sa-ot",
      idCategory: "1",
      price: "119,00",
      isHot: 0,
      description: "Restované hovězí se zeleninou, citronovou trávou a chili ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "11"
    },
    fields: {
      name: "Bo Cari",
      code: "O11",
      slug: "bo-cari",
      idCategory: "1",
      price: "139,00",
      isHot: 0,
      description: "Restované hovězí se kari, zeleninou, kokosovým mlékem a smetanou ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "12"
    },
    fields: {
      name: "Tofu Cari",
      code: "O12",
      slug: "tofu-cari",
      idCategory: "1",
      price: "139,00",
      isHot: 0,
      description: "Restované tofu se zeleninou a červeným cari ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "13"
    },
    fields: {
      name: "Tofu Sa Ot",
      code: "O13",
      slug: "tofu-sa-ot",
      idCategory: "1",
      price: "99,00",
      isHot: 0,
      description: "Restované tofu se zeleninou, citronovou trávou a chili ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "14"
    },
    fields: {
      name: "Tofu Tieu",
      code: "O14",
      slug: "tofu-tieu",
      idCategory: "1",
      price: "99,00",
      isHot: 0,
      description: "Tofu na pepři se zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "15"
    },
    fields: {
      name: "Bun Ga",
      code: "O15",
      slug: "bun-ga",
      idCategory: "1",
      price: "149,00",
      isHot: 0,
      description: "Kulaté rýžové nudle s restovaným kuřecím na citronové trávě, s nakládaným zelím, okurkou, mangem, mungo klíčky, vietnamským salámem a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "16"
    },
    fields: {
      name: "Bun Bo",
      code: "O16",
      slug: "bun-bo",
      idCategory: "1",
      price: "149,00",
      isHot: 0,
      description: "Kulaté rýžové nudle s restovaným hovězím, na citronové trávě, s nakládaným zelím, okurkou, mangem, mungo klíčky, vietnamským salámem a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "17"
    },
    fields: {
      name: "Bun Tofu",
      code: "O17",
      slug: "bun-tofu",
      idCategory: "1",
      price: "149,00",
      isHot: 0,
      description: "Kulaté rýžové nudle s tofu, nakládaným zelím, okurkou, mangem, mungo klíčky, vietnamským salámem a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "18"
    },
    fields: {
      name: "Pho/My Xao Ga",
      code: "O18",
      slug: "phomy-xao-ga",
      idCategory: "1",
      price: "129,00",
      isHot: 0,
      description: "Ploché rýžové nudle / restované nudle s kuřecím masem, zeleninou, arašídy, křupavou cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "19"
    },
    fields: {
      name: "Pho/My Xao Bo",
      code: "O19",
      slug: "phomy-xao-bo",
      idCategory: "1",
      price: "109,00",
      isHot: 0,
      description: "Ploché rýžové nudle / restované nudle s hovězím masem, zeleninou, arašídy, křupavou cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "20"
    },
    fields: {
      name: "Pho/My Xao Tofu",
      code: "O20",
      slug: "phomy-xao-tofu",
      idCategory: "1",
      price: "129,00",
      isHot: 0,
      description: "Ploché rýžové nudle / restované nudle s tofu, zeleninou, arašídy, křupavou cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "369"
    },
    fields: {
      name: "Bun Thit Nuong",
      code: "O40",
      slug: "bun-thit-nuong",
      idCategory: "1",
      price: "149,00",
      isHot: 0,
      description: "Kulaté rýžové nudle s grilovaným vepřovým masem, bylinkami, salatem a speciální omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "21"
    },
    fields: {
      name: "Vařená rýže",
      code: "O21",
      slug: "varena-ryze",
      idCategory: "2",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "22"
    },
    fields: {
      name: "Rýžové nudle",
      code: "O22",
      slug: "ryzove-nudle",
      idCategory: "2",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "23"
    },
    fields: {
      name: "Restovaná rýže",
      code: "O23",
      slug: "restovana-ryze",
      idCategory: "2",
      price: "79,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "24"
    },
    fields: {
      name: "Restované nudle",
      code: "O24",
      slug: "restovane-nudle",
      idCategory: "2",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "322"
    },
    fields: {
      name: "Dětské menu",
      code: "O25",
      slug: "detske-menu",
      idCategory: "2",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "330"
    },
    fields: {
      name: "Hranolky",
      code: "O26",
      slug: "hranolky",
      idCategory: "2",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "339"
    },
    fields: {
      name: "Kuřecí řízek s hranolkami",
      code: "O27",
      slug: "kureci-rizek-s-hranolkami",
      idCategory: "2",
      price: "149,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "342"
    },
    fields: {
      name: "Extra +30Kc",
      code: "O28",
      slug: "extra-30kc",
      idCategory: "2",
      price: "30,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "374"
    },
    fields: {
      name: "Velké Hranolky",
      code: "O29",
      slug: "velke-hranolky",
      idCategory: "2",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "48"
    },
    fields: {
      name: "Bun Suon Moc",
      code: "24",
      slug: "bun-suon-moc",
      idCategory: "3",
      price: "179,00",
      isHot: 0,
      description: "Polévka s kulatými rýžovými nudlemi, žebírky, masovými knedlíčky, cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "50"
    },
    fields: {
      name: "Bun Ca",
      code: "26",
      slug: "bun-ca",
      idCategory: "3",
      price: "189,00",
      isHot: 0,
      description: "Rybí polévka s kapřími karbanátky, kulatými rýžovými nudlemi a bylinkami ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "51"
    },
    fields: {
      name: "Pho Bo",
      code: "27",
      slug: "pho-bo",
      idCategory: "3",
      price: "179,00",
      isHot: 0,
      description: "Pho polévka s hovězím masem, rýžovými nudlemi, cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "52"
    },
    fields: {
      name: "Pho Ga",
      code: "28",
      slug: "pho-ga",
      idCategory: "3",
      price: "169,00",
      isHot: 0,
      description: "Pho polévka s kuřecím masem, rýžovými nudlemi, cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "53"
    },
    fields: {
      name: "Mien Ga",
      code: "29",
      slug: "mien-ga",
      idCategory: "3",
      price: "169,00",
      isHot: 0,
      description: "Mien polévka s kuřecím masem, skleněnými nudlemi, cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "54"
    },
    fields: {
      name: "Mien Thap Cam",
      code: "30",
      slug: "mien-thap-cam",
      idCategory: "3",
      price: "199,00",
      isHot: 0,
      description: "Mien mix polévka s kuřecím/hovězím masem/krevetami, skleněnými nudlemi, cibulkou a bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "323"
    },
    fields: {
      name: "Bun Cha Obama",
      code: "25",
      slug: "bun-cha-obama",
      idCategory: "3",
      price: "179,00",
      isHot: 0,
      description: "Kulaté rýžové nudle s grilovaným vepřovým masem, bylinkami, salatem a speciální omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "366"
    },
    fields: {
      name: "Bun Tom",
      code: "300",
      slug: "bun-tom",
      idCategory: "3",
      price: "189,00",
      isHot: 0,
      description: "Krevety, kulaté rýžové nudle, bylinky, salat a speciální omáčka",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "367"
    },
    fields: {
      name: "Bun Nem",
      code: "301",
      slug: "bun-nem",
      idCategory: "3",
      price: "179,00",
      isHot: 0,
      description: "Závitky, kulaté rýžové nudle, bylinky, salat a speciální omáčka",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "368"
    },
    fields: {
      name: "Bun Nem Mix",
      code: "302",
      slug: "bun-nem-mix",
      idCategory: "3",
      price: "199,00",
      isHot: 0,
      description: "Závitky, mleté vepřové maso v listu batelu, kulaté rýžové nudle, bylinky, salat a speciální omáčka",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "378"
    },
    fields: {
      name: "Pho Tom",
      code: "270",
      slug: "pho-tom",
      idCategory: "3",
      price: "189,00",
      isHot: 0,
      description: "Pho polévka s krevetami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "379"
    },
    fields: {
      name: "Pho Tofu",
      code: "271",
      slug: "pho-tofu",
      idCategory: "3",
      price: "169,00",
      isHot: 0,
      description: "Pho polévka s Tofu",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "380"
    },
    fields: {
      name: "Pho Mix",
      code: "272",
      slug: "pho-mix",
      idCategory: "3",
      price: "199,00",
      isHot: 0,
      description: "Pho polévka mix - různá masa",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "113"
    },
    fields: {
      name: "Ga Sa Ot",
      code: "31",
      slug: "ga-sa-ot",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Smažené kuřecí stehna s citronovou trávou, chili a zeleninou.",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "114"
    },
    fields: {
      name: "Ga Chien Cari",
      code: "32",
      slug: "ga-chien-cari",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Smažené kuřecí prsíčka s kari, citronovou trávou, chili a zeleninou.",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "115"
    },
    fields: {
      name: "Ga Xao Cari",
      code: "33",
      slug: "ga-xao-cari",
      idCategory: "4",
      price: "159,00",
      isHot: 0,
      description: "Restované kuřecí maso se kari, zeleninou, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "116"
    },
    fields: {
      name: "Ga Dao Hao",
      code: "34",
      slug: "ga-dao-hao",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Smažené kuřecí maso na ústřičné omáčce se zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "117"
    },
    fields: {
      name: "Ga La Que",
      code: "35",
      slug: "ga-la-que",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Restované kuřecí maso s bazalkou, chili, zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "118"
    },
    fields: {
      name: "Ga Tieu",
      code: "36",
      slug: "ga-tieu",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Restované kuřecí maso s pepřovou omáčkou a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "119"
    },
    fields: {
      name: "Ga Chua Ngot",
      code: "37",
      slug: "ga-chua-ngot",
      idCategory: "4",
      price: "169,00",
      isHot: 0,
      description: "Smažené kuře se zeleninou na sladko-kyselo",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "120"
    },
    fields: {
      name: "Vit Cari",
      code: "38",
      slug: "vit-cari",
      idCategory: "4",
      price: "229,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou, červeným pepřem, kokosovým mlékem, smetanou ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "121"
    },
    fields: {
      name: "Vit Chien Gung",
      code: "39",
      slug: "vit-chien-gung",
      idCategory: "4",
      price: "229,00",
      isHot: 0,
      description: "Smažená kachna se zázvorem a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "122"
    },
    fields: {
      name: "Vit La Que",
      code: "40",
      slug: "vit-la-que",
      idCategory: "4",
      price: "229,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou, bazalkou, chili a Hoisin omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "123"
    },
    fields: {
      name: "Vit Chien Tieu",
      code: "41",
      slug: "vit-chien-tieu",
      idCategory: "4",
      price: "229,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou na pepřové omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "124"
    },
    fields: {
      name: "Vit Dau Hao",
      code: "42",
      slug: "vit-dau-hao",
      idCategory: "4",
      price: "229,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou na ústřičné omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "125"
    },
    fields: {
      name: "Vit Chua Ngot",
      code: "43",
      slug: "vit-chua-ngot",
      idCategory: "4",
      price: "229,00",
      isHot: 0,
      description: "Smažená kachna se zeleninou na sladko-kyselo",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "126"
    },
    fields: {
      name: "Bo Tieu",
      code: "44",
      slug: "bo-tieu",
      idCategory: "4",
      price: "169,00",
      isHot: 0,
      description: "Restované hovězí se zeleninou na pepřové omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "127"
    },
    fields: {
      name: "Bo La Que",
      code: "45",
      slug: "bo-la-que",
      idCategory: "4",
      price: "169,00",
      isHot: 0,
      description: "Restované hovězí se zeleninou, bazalkou a chili",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "128"
    },
    fields: {
      name: "Bo Cari",
      code: "46",
      slug: "bo-cari",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Restované hovězí se kari, zeleninou, kokosovým mlékem a smetanou ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "129"
    },
    fields: {
      name: "Bo Dau Hao",
      code: "47",
      slug: "bo-dau-hao",
      idCategory: "4",
      price: "169,00",
      isHot: 0,
      description: "Restované hovězí na ústřičné omáčce podávané se zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "130"
    },
    fields: {
      name: "Bo Sa Ot",
      code: "48",
      slug: "bo-sa-ot",
      idCategory: "4",
      price: "169,00",
      isHot: 0,
      description: "Restované hovězí se zeleninou, citronovou trávou a chili ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "131"
    },
    fields: {
      name: "Tom Cari",
      code: "49",
      slug: "tom-cari",
      idCategory: "4",
      price: "219,00",
      isHot: 0,
      description: "Restované krevety se zeleninou, červeným pepřem, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "132"
    },
    fields: {
      name: "Tom Dau Hao",
      code: "50",
      slug: "tom-dau-hao",
      idCategory: "4",
      price: "219,00",
      isHot: 0,
      description: "Restované krevety se zeleninou na ústřičné omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "133"
    },
    fields: {
      name: "Tom Gung",
      code: "51",
      slug: "tom-gung",
      idCategory: "4",
      price: "219,00",
      isHot: 0,
      description: "Restované krevety se zázvorem a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "134"
    },
    fields: {
      name: "Tom Sa Ot",
      code: "52",
      slug: "tom-sa-ot",
      idCategory: "4",
      price: "219,00",
      isHot: 0,
      description: "Restované krevety se zeleninou, citronovou trávou a chili",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "135"
    },
    fields: {
      name: "Tom La Que",
      code: "53",
      slug: "tom-la-que",
      idCategory: "4",
      price: "219,00",
      isHot: 0,
      description: "Restované krevety se zeleninou, bazalkou a chili",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "136"
    },
    fields: {
      name: "Ca Sa Ot",
      code: "54",
      slug: "ca-sa-ot",
      idCategory: "4",
      price: "189,00",
      isHot: 0,
      description: "Smažený pangasius se zeleninou, citronovou trávou a chili ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "137"
    },
    fields: {
      name: "Ca Gung",
      code: "55",
      slug: "ca-gung",
      idCategory: "4",
      price: "189,00",
      isHot: 0,
      description: "Pangasius se zázvorem a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "138"
    },
    fields: {
      name: "Ca Cari",
      code: "56",
      slug: "ca-cari",
      idCategory: "4",
      price: "189,00",
      isHot: 0,
      description: "Smažený pangasius se zeleninou, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "139"
    },
    fields: {
      name: "Ca Chua Ngot",
      code: "57",
      slug: "ca-chua-ngot",
      idCategory: "4",
      price: "189,00",
      isHot: 0,
      description: "Smažený pangasius se kari, zeleninou na sladko-kyselo",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "140"
    },
    fields: {
      name: "Ca Tieu",
      code: "58",
      slug: "ca-tieu",
      idCategory: "4",
      price: "189,00",
      isHot: 0,
      description: "Smažený pangasius se zeleninou v pepřové omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "141"
    },
    fields: {
      name: "Ca Dau Hao",
      code: "59",
      slug: "ca-dau-hao",
      idCategory: "4",
      price: "159,00",
      isHot: 0,
      description: "Smažený pangasius se zeleninou v ústřičné omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "142"
    },
    fields: {
      name: "Lon La Que",
      code: "59",
      slug: "lon-la-que",
      idCategory: "4",
      price: "159,00",
      isHot: 0,
			description: "vepřové maso se zeleninou a bazalkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "145"
    },
    fields: {
      name: "Lon Chien Chua Ngot",
      code: "63",
      slug: "lon-chien-chua-ngot",
      idCategory: "4",
      price: "169,00",
      isHot: 0,
      description: "Smažené vepřové kousky se zeleninou na sladko-kyselo",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "146"
    },
    fields: {
      name: "Tofu Cari",
      code: "64",
      slug: "tofu-cari",
      idCategory: "4",
      price: "159,00",
      isHot: 0,
      description: "Tofu se zeleninou a červeným cari",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "147"
    },
    fields: {
      name: "Tofu Tieu",
      code: "65",
      slug: "tofu-tieu",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Tofu na pepři se zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "148"
    },
    fields: {
      name: "Tofu La Que",
      code: "66",
      slug: "tofu-la-que",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Tofu se bazalkou a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "149"
    },
    fields: {
      name: "Tofu Gung",
      code: "67",
      slug: "tofu-gung",
      idCategory: "4",
      price: "139,00",
      isHot: 0,
      description: "Restované Tofu na zázvorové omáčce se zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "150"
    },
    fields: {
      name: "Tofu Sa Ot",
      code: "68",
      slug: "tofu-sa-ot",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Tofu se zeleninou, citronovou trávou a chili",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "151"
    },
    fields: {
      name: "Tofu Dau Hao",
      code: "69",
      slug: "tofu-dau-hao",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
			description: "Tofu se zeleninou na ústřičné omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "370"
    },
    fields: {
      name: "Ga Xao Sa Ot",
      code: "370",
      slug: "ga-xao-sa-ot",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Restované kuřecí maso s citronovou trávou, chili a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "371"
    },
    fields: {
      name: "Kung Pao Ga",
      code: "371",
      slug: "kung-pao-ga",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Kung pao s kuřecím masem",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "372"
    },
    fields: {
      name: "Vit Sa Ot",
      code: "430",
      slug: "vit-sa-ot",
      idCategory: "4",
      price: "229,00",
      isHot: 0,
      description: "Kachna s citronovou trávou, chili a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "381"
    },
    fields: {
      name: "Ga Xao Gung",
      code: "372",
      slug: "ga-xao-gung",
      idCategory: "4",
      price: "149,00",
      isHot: 0,
      description: "Kuřecí restované maso s zázvorem",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "382"
    },
    fields: {
      name: "Bo Gung",
      code: "480",
      slug: "bo-gung",
      idCategory: "4",
      price: "169,00",
      isHot: 0,
      description: "Hovězí restované maso s zázvorem",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "383"
    },
    fields: {
      name: "Tom Tieu",
      code: "530",
      slug: "tom-tieu",
      idCategory: "4",
      price: "219,00",
      isHot: 0,
      description: "Restované krevety se zeleninou na pepřové omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "384"
    },
    fields: {
      name: "Cá La Que",
      code: "590",
      slug: "ca-la-que",
      idCategory: "4",
      price: "189,00",
      isHot: 0,
      description: "Smažená ryba se zeleninou, bazalkou, chili a Hoisin omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "385"
    },
    fields: {
      name: "Cá Dau Hao",
      code: "591",
      slug: "ca-dau-hao",
      idCategory: "4",
      price: "189,00",
      isHot: 0,
      description: "Smažená ryba se zeleninou na ústřičné omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "386"
    },
    fields: {
      name: "Lon Sa ot",
      code: "630",
      slug: "lon-sa-ot",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Restované vepřové maso se zeleninou, citronovou trávou a chili ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "387"
    },
    fields: {
      name: "Lon Tieu",
      code: "631",
      slug: "lon-tieu",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Restované vepřové maso se zeleninou na pepřové omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "388"
    },
    fields: {
      name: "Lon Dau Hao",
      code: "632",
      slug: "lon-dau-hao",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Smažené vepřové maso se zeleninou na ústřičné omáčce",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "389"
    },
    fields: {
      name: "Lon Gung",
      code: "633",
      slug: "lon-gung",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Vepřové restované maso s zázvorem",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "392"
    },
    fields: {
      name: "Lon Cari",
      code: "62",
      slug: "lon-cari",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "vepřové maso s kari, zeleninou, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "396"
    },
    fields: {
      name: "My Xao Nem",
      code: "113",
      slug: "My-Xao-Nem",
      idCategory: "4",
      price: "179,00",
      isHot: 0,
      description: "Restované nudle se závitky",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "153"
    },
    fields: {
      name: "Mix na plotýnce",
      code: "71",
      slug: "mix-na-plotynce",
      idCategory: "5",
      price: "279,00",
      isHot: 0,
      description: "Smažené kachny, kuřecí, vepřové, hovězí maso a krevety",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "154"
    },
    fields: {
      name: "Com Chay Hai San",
      code: "72",
      slug: "com-chay-hai-san",
      idCategory: "5",
      price: "279,00",
      isHot: 0,
      description: "Křupavá rýže s mořskými plody",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "155"
    },
    fields: {
      name: "My Xao gion Thap Cam",
      code: "73",
      slug: "my-xao-gion-thap-cam",
      idCategory: "5",
      price: "279,00",
      isHot: 0,
      description: "Křupavé nudle",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "156"
    },
    fields: {
      name: "Com Len Dia",
      code: "74",
      slug: "com-len-dia",
      idCategory: "5",
      price: "299,00",
      isHot: 0,
      description: "Kuřecí maso, kachna, 2 krevety, restovaná zelenina",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "157"
    },
    fields: {
      name: "My Xao Thap Cam",
      code: "75",
      slug: "my-xao-thap-cam",
      idCategory: "5",
      price: "279,00",
      isHot: 0,
      description: "Restované nudle, kuře, Krevety, kachna, 2 krabí klepeta",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "158"
    },
    fields: {
      name: "Mien Xao Thap Cam",
      code: "76",
      slug: "mien-xao-thap-cam",
      idCategory: "5",
      price: "279,00",
      isHot: 0,
      description: "Restované skleněné nudle, kuře, 2 krevety, 2 krabí klepeta, kachna",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "357"
    },
    fields: {
      name: "My Xao Chay",
      code: "103",
      slug: "my-xao-chay",
      idCategory: "5",
      price: "129,00",
      isHot: 0,
      description: "Ploché rýžové nudle se zeleninou, křupavou cibulkou, arašídy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "358"
    },
    fields: {
      name: "My Xao Vit",
      code: "104",
      slug: "my-xao-vit",
      idCategory: "5",
      price: "229,00",
      isHot: 0,
      description: "Kachna, ploché rýžové nudle se zeleninou, křupavou cibulkou, arašídy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "359"
    },
    fields: {
      name: "My Xao Tom",
      code: "105",
      slug: "my-xao-tom",
      idCategory: "5",
      price: "199,00",
      isHot: 0,
      description: "Krevety, ploché rýžové nudle se zeleninou, křupavou cibulkou, arašídy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "360"
    },
    fields: {
      name: "Com Rang Ga",
      code: "106",
      slug: "com-rang-ga",
      idCategory: "5",
      price: "169,00",
      isHot: 0,
      description: "Smažená rýže s kuřecím masem a zeleninou, křupavou cibulkou, arašidy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "361"
    },
    fields: {
      name: "Com Rang Bo",
      code: "107",
      slug: "com-rang-bo",
      idCategory: "5",
      price: "189,00",
      isHot: 0,
      description: "Smažená rýže s hovězím masem, zeleninou, křupavou cibulkou, arašidy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "362"
    },
    fields: {
      name: "Com Rang Tom",
      code: "108",
      slug: "com-rang-tom",
      idCategory: "5",
      price: "199,00",
      isHot: 0,
      description: "Smažená rýže s krevetami, zeleninou, křupavou cibulkou, arašidy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "363"
    },
    fields: {
      name: "Com Rang Vit",
      code: "109",
      slug: "com-rang-vit",
      idCategory: "5",
      price: "229,00",
      isHot: 0,
      description: "Smažená rýže s kachnou, zeleninou, křupavou cibulkou, arašidy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "364"
    },
    fields: {
      name: "Com Rang Tofu",
      code: "110",
      slug: "com-rang-tofu",
      idCategory: "5",
      price: "169,00",
      isHot: 0,
      description: "Smažená rýže s tofu, zeleninou, křupavou cibulkou, arašidy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "365"
    },
    fields: {
      name: "Com Rang Mix",
      code: "111",
      slug: "com-rang-mix",
      idCategory: "5",
      price: "279,00",
      isHot: 0,
      description: "Smažená rýže s ruznými druhy masa, zeleninou, křupavou cibulkou, arašidy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "375"
    },
    fields: {
      name: "Com rang chay",
      code: "122",
      slug: "com-rang-chay",
      idCategory: "5",
      price: "119,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "390"
    },
    fields: {
      name: "Mien Xao Ga",
      code: "760",
      slug: "mien-xao-ga",
      idCategory: "5",
      price: "179,00",
      isHot: 0,
      description: "Restované skleněné nudle s kuřecím masem",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "159"
    },
    fields: {
      name: "Bun Tron Ga",
      code: "77",
      slug: "bun-tron-ga",
      idCategory: "3",
      price: "179,00",
      isHot: 0,
      description: "Rýžové nudle, kuřecí maso, vietnamský salám, vietnamská sekaná, zelenina, okurka, mungo klíčky, mango, salát, bylinky, cibulka, arašídy a sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "160"
    },
    fields: {
      name: "Bun Tron Bo",
      code: "78",
      slug: "bun-tron-bo",
      idCategory: "3",
      price: "189,00",
      isHot: 0,
      description: "Hovězí maso, rýžové nudle, vietnamský salám, vietnamská sekaná, zelenina, okurka, mungo klíčky, mango, salát, bylinky, cibulka, arašídy a sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "161"
    },
    fields: {
      name: "Bun Tron Vit",
      code: "79",
      slug: "bun-tron-vit",
      idCategory: "3",
      price: "229,00",
      isHot: 0,
      description: "Kachna, rýžové nudle, vietnamský salám, vietnamská sekaná, zelenina, okurka, mungo klíčky, mango, salát, bylinky, cibulka, arašídy a sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "162"
    },
    fields: {
      name: "Bun Tron Tom",
      code: "80",
      slug: "bun-tron-tom",
      idCategory: "3",
      price: "199,00",
      isHot: 0,
      description: "Krevety, rýžové nudle, vietnamský salám, vietnamská sekaná, zelenina, okurka, mungo klíčky, mango, salát, bylinky, cibulka, arašídy a sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "163"
    },
    fields: {
      name: "Bun Tron Tofu",
      code: "81",
      slug: "bun-tron-tofu",
      idCategory: "3",
      price: "179,00",
      isHot: 0,
      description: "Tofu, rýžové nudle, vietnamský salám, vietnamská sekaná, zelenina, okurka, mungo klíčky, mango, salát, bylinky, cibulka, arašídy a sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "336"
    },
    fields: {
      name: "Pho/My Xao Ga",
      code: "99",
      slug: "phomy-xao-ga",
      idCategory: "3",
      price: "189,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "337"
    },
    fields: {
      name: "Pho/My Xao Bo",
      code: "100",
      slug: "phomy-xao-bo",
      idCategory: "3",
      price: "189,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "341"
    },
    fields: {
      name: "Pho/My Xao Tofu",
      code: "98",
      slug: "phomy-xao-tofu",
      idCategory: "3",
      price: "169,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "164"
    },
    fields: {
      name: "Tu Cuon Ga",
      code: "82",
      slug: "tu-cuon-ga",
      idCategory: "5",
      price: "269,00",
      isHot: 0,
      description: "„Ubal si sám“ zahrnuje: Rýžový papír, rýžové nudle, salát, bylinky, mango, sezam, omáčku a maso dle výběru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "165"
    },
    fields: {
      name: "Tu Cuon Vit",
      code: "83",
      slug: "tu-cuon-vit",
      idCategory: "5",
      price: "299,00",
      isHot: 0,
      description: "„Ubal si sám“ zahrnuje: Rýžový papír, rýžové nudle, salát, bylinky, mango, sezam, omáčku a maso dle výběru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "166"
    },
    fields: {
      name: "Tu Cuon Bo",
      code: "84",
      slug: "tu-cuon-bo",
      idCategory: "5",
      price: "279,00",
      isHot: 0,
      description: "„Ubal si sám“ zahrnuje: Rýžový papír, rýžové nudle, salát, bylinky, mango, sezam, omáčku a maso dle výběru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "167"
    },
    fields: {
      name: "Tu Cuon Tom",
      code: "85",
      slug: "tu-cuon-tom",
      idCategory: "5",
      price: "289,00",
      isHot: 0,
      description: "„Ubal si sám“ zahrnuje: Rýžový papír, rýžové nudle, salát, bylinky, mango, sezam, omáčku a maso dle výběru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "332"
    },
    fields: {
      name: "Tu Cuon Tofu",
      code: "86",
      slug: "tu-cuon-tofu",
      idCategory: "5",
      price: "269,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "168"
    },
    fields: {
      name: "Banh Cuon Cha Que",
      code: "87",
      slug: "banh-cuon-cha-que",
      idCategory: "5",
      price: "179,00",
      isHot: 0,
      description: "Rýžové palačinky s vietnamskou sekanou, bylinkami, salátem a speciální omáčkou ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "169"
    },
    fields: {
      name: "Banh Cuon Nem",
      code: "88",
      slug: "banh-cuon-nem",
      idCategory: "5",
      price: "179,00",
      isHot: 0,
      description: "Rýžové palačinky se závitky, bylinkami, salátem a speciální omáčkou ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "170"
    },
    fields: {
      name: "Banh Cuon Thit Nuong",
      code: "89",
      slug: "banh-cuon-thit-nuong",
      idCategory: "5",
      price: "189,00",
      isHot: 0,
      description: "Rýžové palačinky s grilovaným vepřovým, bylinkami, salátem a speciální omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "196"
    },
    fields: {
      name: "Nigiri Ebi",
      code: "S1",
      slug: "nigiri-ebi",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks Vařená kreveta",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "197"
    },
    fields: {
      name: "Nigiri Maguro",
      code: "S2",
      slug: "nigiri-maguro",
      idCategory: "9",
      price: "119,00",
      isHot: 0,
      description: "2ks Tuňák",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "198"
    },
    fields: {
      name: "Nigiri Sake",
      code: "S3",
      slug: "nigiri-sake",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks Losos",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "199"
    },
    fields: {
      name: "Nigiri Tako",
      code: "S4",
      slug: "nigiri-tako",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks Chobotnice",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "200"
    },
    fields: {
      name: "Nigiri Unagi",
      code: "S5",
      slug: "nigiri-unagi",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks Úhoř",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "201"
    },
    fields: {
      name: "Nigiri Inagi",
      code: "S6",
      slug: "nigiri-inagi",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks Tofu",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "202"
    },
    fields: {
      name: "Nigiri Surimi",
      code: "S7",
      slug: "nigiri-surimi",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks Krabí klepeta",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "203"
    },
    fields: {
      name: "Avokádo Nigiri",
      code: "S8",
      slug: "avokado-nigiri",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "204"
    },
    fields: {
      name: "Nigiri Omeleta",
      code: "S9",
      slug: "nigiri-omeleta",
      idCategory: "9",
      price: "89,00",
      isHot: 0,
      description: "2ks",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "205"
    },
    fields: {
      name: "Kappa",
      code: "S10",
      slug: "kappa",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "6ks Okurka, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "206"
    },
    fields: {
      name: "Avokádo",
      code: "S11",
      slug: "avokado",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "6ks Avokádo, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "207"
    },
    fields: {
      name: "Oshinko",
      code: "S12",
      slug: "oshinko",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "6ks Nakládaná ředkev, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "208"
    },
    fields: {
      name: "Hoso Maki Mix",
      code: "S13",
      slug: "hoso-maki-mix",
      idCategory: "9",
      price: "119,00",
      isHot: 0,
      description: "6ks Losos, avokádo, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "209"
    },
    fields: {
      name: "Tekka",
      code: "S14",
      slug: "tekka",
      idCategory: "9",
      price: "119,00",
      isHot: 0,
      description: "6ks Tuňák",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "210"
    },
    fields: {
      name: "Sake",
      code: "S15",
      slug: "sake",
      idCategory: "9",
      price: "109,00",
      isHot: 0,
      description: "6ks Losos",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "211"
    },
    fields: {
      name: "Surini",
      code: "S16",
      slug: "surini",
      idCategory: "9",
      price: "109,00",
      isHot: 0,
      description: "6ks Krabí klepeta",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "212"
    },
    fields: {
      name: "Garnelen",
      code: "S17",
      slug: "garnelen",
      idCategory: "9",
      price: "109,00",
      isHot: 0,
      description: "6ks Vařená kreveta",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "213"
    },
    fields: {
      name: "Maki Omeleta",
      code: "S18",
      slug: "maki-omeleta",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "6ks",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "214"
    },
    fields: {
      name: "Alaska",
      code: "S20",
      slug: "alaska",
      idCategory: "9",
      price: "219,00",
      isHot: 0,
      description: "8ks Losos, okurka, kaviár, avokádo, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "215"
    },
    fields: {
      name: "Alaska Kaviar",
      code: "S21",
      slug: "alaska-kaviar",
      idCategory: "9",
      price: "229,00",
      isHot: 0,
      description: "8ks Losos, kaviár, okurka, avokádo, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "216"
    },
    fields: {
      name: "California",
      code: "S22",
      slug: "california",
      idCategory: "9",
      price: "219,00",
      isHot: 0,
      description: "8ks Krabí klepeta, avokádo, sezam)",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "217"
    },
    fields: {
      name: "California Speciál",
      code: "S23",
      slug: "california-special",
      idCategory: "9",
      price: "229,00",
      isHot: 0,
      description: "8ks Vařená kreveta, kaviár, avokádo, okurka",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "219"
    },
    fields: {
      name: "Maguro Pirika",
      code: "S25",
      slug: "maguro-pirika",
      idCategory: "9",
      price: "239,00",
      isHot: 0,
      description: "8ks Tuňák, okurka, chili omáčka, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "220"
    },
    fields: {
      name: "Sake Pirika",
      code: "S26",
      slug: "sake-pirika",
      idCategory: "9",
      price: "239,00",
      isHot: 0,
      description: "8ks Losos, okurka, chili omáčka, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "221"
    },
    fields: {
      name: "Kuře Maki",
      code: "S27",
      slug: "kure-maki",
      idCategory: "9",
      price: "209,00",
      isHot: 0,
      description: "8ks Kuřecí řízek, chili omáčka, majonéza, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "222"
    },
    fields: {
      name: "Kachna Maki",
      code: "S28",
      slug: "kachna-maki",
      idCategory: "9",
      price: "229,00",
      isHot: 0,
      description: "8ks Smažená kachna, chili omáčka, majonéza, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "223"
    },
    fields: {
      name: "Hot Maki Sushi",
      code: "S29",
      slug: "hot-maki-sushi",
      idCategory: "9",
      price: "249,00",
      isHot: 0,
      description: "8ks Losos, avokádo, nakládaná ředkev",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "224"
    },
    fields: {
      name: "Vege Maki",
      code: "S30",
      slug: "vege-maki",
      idCategory: "9",
      price: "219,00",
      isHot: 0,
      description: "8ks Okurka, avokádo, nakládaná tykev, philadelphia, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "226"
    },
    fields: {
      name: "Ebi Tempura",
      code: "S32",
      slug: "ebi-tempura",
      idCategory: "9",
      price: "249,00",
      isHot: 0,
      description: "8ks Smažená kreveta v tempuře, avokádo, majonéza, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "227"
    },
    fields: {
      name: "Fish Futo",
      code: "S40",
      slug: "fish-futo",
      idCategory: "9",
      price: "229,00",
      isHot: 0,
      description: "6ks Losos, tuňák, okurka, avokádo, kaviár",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "228"
    },
    fields: {
      name: "Vege Futo",
      code: "S41",
      slug: "vege-futo",
      idCategory: "9",
      price: "219,00",
      isHot: 0,
      description: "6ks Omeleta, okurka, avokádo, nakládaná tykev, nakládaná ředkev, tofu, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "373"
    },
    fields: {
      name: "Futo Maki Tempura",
      code: "S42",
      slug: "futo-maki-tempura",
      idCategory: "9",
      price: "219,00",
      isHot: 0,
      description: "6ks Smažené krevety v tempuře, avokádo, okurka, majonéza, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "229"
    },
    fields: {
      name: "Nigiri BBQ Tuňák",
      code: "S50",
      slug: "2ks nigiri-bbq-tuak",
      idCategory: "9",
      price: "119,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "230"
    },
    fields: {
      name: "Nigiri BBQ Losos",
      code: "S51",
      slug: "2ks nigiri-bbq-losos",
      idCategory: "9",
      price: "109,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "231"
    },
    fields: {
      name: "Ura Maki BBQ Tuňák",
      code: "S52",
      slug: "8ks ura-maki-bbq-tuak",
      idCategory: "9",
      price: "299,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "232"
    },
    fields: {
      name: "Ura Maki BBQ Losos",
      code: "S53",
      slug: "8ks ura-maki-bbq-losos",
      idCategory: "9",
      price: "299,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "233"
    },
    fields: {
      name: "Sashimi Losos",
      code: "S61",
      slug: "sashimi-losos",
      idCategory: "9",
      price: "99,00",
      isHot: 0,
      description: "2ks",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "234"
    },
    fields: {
      name: "Sashimi Tuňák",
      code: "S62",
      slug: "sashimi-tuak",
      idCategory: "9",
      price: "109,00",
      isHot: 0,
      description: "2ks",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "235"
    },
    fields: {
      name: "Sashimi Chobotnice",
      code: "S63",
      slug: "sashimi-chobotnice",
      idCategory: "9",
      price: "79,00",
      isHot: 0,
      description: "2ks",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "236"
    },
    fields: {
      name: "Nori Maki Mix",
      code: "M1",
      slug: "nori-maki-mix",
      idCategory: "9",
      price: "299,00",
      isHot: 0,
      description: "12 ks Mix-Maki (losos, tuňák, okurka, nakládaná ředkev), 4ks california special)",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "237"
    },
    fields: {
      name: "Koi-Sigler",
      code: "M2",
      slug: "koi-sigler",
      idCategory: "9",
      price: "299,00",
      isHot: 0,
      description: "2 ks Nigiri losos, tuňák, 6 ks Mix-Maki avokádo, okurka, 4 ks Maki Alaska",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "238"
    },
    fields: {
      name: "Koi-Miss",
      code: "M3",
      slug: "koi-miss",
      idCategory: "9",
      price: "399,00",
      isHot: 0,
      description: "3ks  Nigiri (losos, tuňák, mořský úhoř), 18 ks Mix-Maki (losos, tuňák, okurka, nakládaná ředkev), omeleta) ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "239"
    },
    fields: {
      name: "Happy-Koi",
      code: "M4",
      slug: "happy-koi",
      idCategory: "9",
      price: "489,00",
      isHot: 0,
      description: "4ks Nigiri (losos, tuňák, vařená kreveta, chobotnice), 6ks Mix – maki (krabí klepeta, nakládaná ředkev), 4ks Alaska kaviár, 4ks California Special",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "240"
    },
    fields: {
      name: "Vege Sushi",
      code: "M5",
      slug: "vege-sushi",
      idCategory: "9",
      price: "379,00",
      isHot: 0,
      description: "2ks Nigiri (avokádo, tofu) 18ks Mix-Maki (avokádo, okurka, nakládaná ředkev)",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "241"
    },
    fields: {
      name: "Mix Sushi",
      code: "M6",
      slug: "mix-sushi",
      idCategory: "9",
      price: "599,00",
      isHot: 0,
      description: "6ks Nigiri: Losos, mořský úhoř, chobotnice, tuňák, krabí klepeta, vařené krevety; 6ks Maki-Mix: avokado, vařená kreveta; 6ks Futomaki: smažená kreveta v tempuře, avokado, okurka, majonéza, sezam",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "242"
    },
    fields: {
      name: "Tokio Bento Box (pro 2 osoby)",
      code: "M7",
      slug: "tokio-bento-box-pro-2-osoby",
      idCategory: "9",
      price: "1299,00",
      isHot: 0,
      description: "10 ks Nigiri (2x losos, 2x tuňák, 2x vařená kreveta, 2x krabí klepeta, 2x mořský úhoř); 10 ks Hot-Maki-Sushi; 6 ks Sahimi: losos, tuňák, chobotnice; 8 ks Alaska kaviár",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "243"
    },
    fields: {
      name: "Tokio Sashimi",
      code: "M8",
      slug: "tokio-sashimi",
      idCategory: "9",
      price: "449,00",
      isHot: 0,
      description: "3ks losos, 3ks tuňák, 3ks chobotnice, 1ks kaviár 399",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "25"
    },
    fields: {
      name: "Sup Van Than",
      code: "1",
      slug: "sup-van-than",
      idCategory: "16",
      price: "59,00",
      isHot: 0,
      description: "Polévka Van Than podávaná s knedlíčky Van Than, zeleninou a čerstvými bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "26"
    },
    fields: {
      name: "Sup Cang Cua",
      code: "2",
      slug: "sup-cang-cua",
      idCategory: "16",
      price: "59,00",
      isHot: 0,
      description: "Polévka Cang Cua s kousíčky masa z krabích klepet, rozšlehaným vajíčkem a zeleninou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "27"
    },
    fields: {
      name: "Sup Mien Ga",
      code: "3",
      slug: "sup-mien-ga",
      idCategory: "16",
      price: "59,00",
      isHot: 0,
      description: "Polévka se skleněnými nudlemi, kuřecím masem, masovými kuličkami, zeleninou a čerstvými bylinkami",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "28"
    },
    fields: {
      name: "Sup Ga Cari",
      code: "4",
      slug: "sup-ga-cari",
      idCategory: "16",
      price: "59,00",
      isHot: 0,
      description: "Polévka Ga Cari s kuřecím masem, cari, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "29"
    },
    fields: {
      name: "Sup Peking",
      code: "5",
      slug: "sup-peking",
      idCategory: "16",
      price: "59,00",
      isHot: 0,
      description: "Pekingská polévka",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "347"
    },
    fields: {
      name: "Sup Tom Cari",
      code: "400",
      slug: "sup-tom-cari",
      idCategory: "16",
      price: "69,00",
      isHot: 0,
      description: "Polévka Tom Cari s krevetami, cari, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "348"
    },
    fields: {
      name: "Sup Tofu Cari",
      code: "401",
      slug: "sup-tofu-cari",
      idCategory: "16",
      price: "59,00",
      isHot: 0,
      description: "Polévka Tofu Cari s tofu, cari, kokosovým mlékem a smetanou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "30"
    },
    fields: {
      name: "Jarní závitky (3ks)",
      code: "6",
      slug: "jarni-zavitky-3ks",
      idCategory: "18",
      price: "89,00",
      isHot: 0,
      description: "Vepřové maso, skleněné nudle, vejce, zelí, karotka balené v rýžovém papíru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "31"
    },
    fields: {
      name: "Jarní závitky s krevetami (3ks)",
      code: "7",
      slug: "jarni-zavitky-s-krevetami-3ks",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Vepřové maso, krevety, skleněné nudle, vejce, zelí, karotka balené v rýžovém papíru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "32"
    },
    fields: {
      name: "Wonton knedlíčky (4ks)",
      code: "8",
      slug: "wonton-knedlicky-4ks",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Krevety, vepřové maso, cibulka",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "33"
    },
    fields: {
      name: "Ha cao knedlíčky (4ks)",
      code: "9",
      slug: "ha-cao-knedlicky-4ks",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Krevety, vepřové maso, různé druhy zeleniny",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "34"
    },
    fields: {
      name: "La Lot (4ks)",
      code: "10",
      slug: "la-lot-4ks",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Mleté vepřové maso s jemně nasekanou cibulkou obalené v listu betelu",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "35"
    },
    fields: {
      name: "Goi Cuon Ga (2ks)",
      code: "11",
      slug: "goi-cuon-ga-2ks",
      idCategory: "18",
      price: "79,00",
      isHot: 0,
      description: "Čerstvé rolky s kuřecím masem, okurkou, arašídy, mungo klíčky, bylinkami, podávané s rybí omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "36"
    },
    fields: {
      name: "Goi Cuon Bo",
      code: "12",
      slug: "goi-cuon-bo",
      idCategory: "18",
      price: "79,00",
      isHot: 0,
      description: "Čerstvé rolky s hovězím masem, okurkou, arašídy, mungo klíčky, bylinkami podávané s rybí omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "37"
    },
    fields: {
      name: "Goi Cuon Tofu",
      code: "13",
      slug: "goi-cuon-tofu",
      idCategory: "18",
      price: "79,00",
      isHot: 0,
      description: "Čerstvé rolky s tofu, okurkou, arašídy, mungo klíčky, bylinkami podávané s rybí omáčkou (krevety za příplatek 10 Kc)",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "38"
    },
    fields: {
      name: "Ga Lui (3ks)",
      code: "14",
      slug: "ga-lui-3ks",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Kuřecí špíz",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "40"
    },
    fields: {
      name: "Tom Chien (3ks)",
      code: "16",
      slug: "tom-chien-3ks",
      idCategory: "18",
      price: "129,00",
      isHot: 0,
      description: "Krevety v tempuře podávané s rybí omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "42"
    },
    fields: {
      name: "Banh Tom (2ks)",
      code: "18",
      slug: "banh-tom-2ks",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Krevetí placky s batáty podávané s rybí omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "43"
    },
    fields: {
      name: "Banh Bao Vit (2ks)",
      code: "19",
      slug: "banh-bao-vit-2ks",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Knedlíčky s kachním masem, okurkou, nakládaným zelím, chili podávané se sójovou omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "44"
    },
    fields: {
      name: "Banh Bao XXL",
      code: "20",
      slug: "banh-bao-xxl",
      idCategory: "18",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "349"
    },
    fields: {
      name: "Goi Cuon Tom",
      code: "120",
      slug: "goi-cuon-tom",
      idCategory: "18",
      price: "89,00",
      isHot: 0,
      description: "Čerstvé rolky s krevetami, okurkou, arašídy, mungo klíčky, bylinkami podávané s rybí omáčkou",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "350"
    },
    fields: {
      name: "Goi Cuon Surimi",
      code: "121",
      slug: "goi-cuon-surimi",
      idCategory: "18",
      price: "79,00",
      isHot: 0,
      description: "Čerstvé rolky s krabí klepetou, okurkou, arašídy, mungo klíčky, bylinkami podávané s rybí omáčkou (krevety za příplatek 10 Kc)",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "45"
    },
    fields: {
      name: "Goi Xoai Vit",
      code: "21",
      slug: "goi-xoai-vit",
      idCategory: "18",
      price: "129,00",
      isHot: 0,
      description: "Kousky kachny s mangem a bylinkami zabalené v rýžovém papíru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "46"
    },
    fields: {
      name: "Goi Du Du Tom",
      code: "22",
      slug: "goi-du-du-tom",
      idCategory: "18",
      price: "129,00",
      isHot: 0,
      description: "Krevety s kousky papáji a bylinkami zabalené v rýžovém papíru",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "47"
    },
    fields: {
      name: "Salat Ga XXL",
      code: "23",
      slug: "salat-ga-xxl",
      idCategory: "18",
      price: "159,00",
      isHot: 0,
      description: "Kuřecí kousky se salátem, avokádem, bylinkami, okurkou, červenou cibulí, cherry rajčátky, mrkví, arašídy a koriandrem",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "351"
    },
    fields: {
      name: "Goi Xoai Ga",
      code: "210",
      slug: "goi-xoai-ga",
      idCategory: "18",
      price: "99,00",
      isHot: 0,
      description: "Kuřecí maso, mango, karotka, červená paprika, mungo klíčky a bilinky",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "352"
    },
    fields: {
      name: "Goi Xoai Tom",
      code: "211",
      slug: "goi-xoai-tom",
      idCategory: "18",
      price: "129,00",
      isHot: 0,
      description: "Krevety, mango, karotka, červená paprika, mungo klíčky a bilinky",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "353"
    },
    fields: {
      name: "Goi Xoai Bo Kho",
      code: "212",
      slug: "goi-xoai-bo-kho",
      idCategory: "18",
      price: "129,00",
      isHot: 0,
      description: "Sušené palivé hovězí maso, mango, karotka, červená paprika, mungo klíčky a bilinky",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "354"
    },
    fields: {
      name: "Salat Bo",
      code: "230",
      slug: "salat-bo",
      idCategory: "18",
      price: "179,00",
      isHot: 0,
      description: "Hovězí maso, avokádo, okurka, cherry rajčátka, mrkev, červená cibule, arašídy a koriandr, bilinky",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "355"
    },
    fields: {
      name: "Salat Tom",
      code: "231",
      slug: "salat-tom",
      idCategory: "18",
      price: "199,00",
      isHot: 0,
      description: "Krevety, avokádo, okurka, cherry rajčátka, mrkev, červená cibule, arašídy a koriandr, bilinky",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "356"
    },
    fields: {
      name: "Salat Vit",
      code: "232",
      slug: "salat-vit",
      idCategory: "18",
      price: "199,00",
      isHot: 0,
      description: "Kachna, avokádo, okurka, cherry rajčátka, mrkev, červená cibule, arašídy a koriandr, bilinky",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "171"
    },
    fields: {
      name: "Banh Mochi",
      code: "A1",
      slug: "banh-mochi",
      idCategory: "19",
      price: "69,00",
      isHot: 0,
      description: "Sladké rýžové knedlíčky Mochi",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "173"
    },
    fields: {
      name: "Che Chuoi",
      code: "A3",
      slug: "che-chuoi",
      idCategory: "19",
      price: "69,00",
      isHot: 0,
      description: "Banán, tapiokové perly, kokosové mléko, arašídy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "174"
    },
    fields: {
      name: "Chuoi Chien",
      code: "A4",
      slug: "chuoi-chien",
      idCategory: "19",
      price: "69,00",
      isHot: 0,
      description: "Banány v těstíčku, med, zmrzlina ",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "175"
    },
    fields: {
      name: "Kem Hoa Qua",
      code: "A5",
      slug: "kem-hoa-qua",
      idCategory: "19",
      price: "79,00",
      isHot: 0,
      description: "Ovocná zmrzlina",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "176"
    },
    fields: {
      name: "Creme Brulee",
      code: "A6",
      slug: "creme-brulee",
      idCategory: "19",
      price: "59,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "177"
    },
    fields: {
      name: "Xoi Xoai Cot Dua",
      code: "A7",
      slug: "xoi-xoai-cot-dua",
      idCategory: "19",
      price: "89,00",
      isHot: 0,
      description: "Lepkavá rýže, mango, kokosové mléko",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "178"
    },
    fields: {
      name: "Caramen",
      code: "A8",
      slug: "caramen",
      idCategory: "19",
      price: "49,00",
      isHot: 0,
      description: "Vařená kreveta",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "244"
    },
    fields: {
      name: "Coca cola 0.33l",
      code: "500",
      slug: "coca-cola-033l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "245"
    },
    fields: {
      name: "Sprite 0.33l",
      code: "501",
      slug: "sprite-033l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "246"
    },
    fields: {
      name: "Fanta 0.33l",
      code: "502",
      slug: "fanta-033l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "247"
    },
    fields: {
      name: "Cappy pomeranč 0.20l",
      code: "503",
      slug: "cappy-pomeranc-020l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "248"
    },
    fields: {
      name: "Maracuja 0.35l",
      code: "504",
      slug: "maracuja-035l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "249"
    },
    fields: {
      name: "Litchi 0.33l",
      code: "505",
      slug: "litchi-033l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "250"
    },
    fields: {
      name: "Mango 0.33l",
      code: "506",
      slug: "mango-033l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "251"
    },
    fields: {
      name: "Guava 0.32l",
      code: "507",
      slug: "guava-032l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "252"
    },
    fields: {
      name: "Kokos 0.35l",
      code: "508",
      slug: "kokos-035l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "299"
    },
    fields: {
      name: "Romerqelle",
      code: "509",
      slug: "romerqelle",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "300"
    },
    fields: {
      name: "Redbull",
      code: "510",
      slug: "redbull",
      idCategory: "22",
      price: "49,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "306"
    },
    fields: {
      name: "Kinley Tonic",
      code: "511",
      slug: "kinley-tonic",
      idCategory: "22",
      price: "39,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "324"
    },
    fields: {
      name: "Cappy multivitamin 0.20l",
      code: "512",
      slug: "cappy-multivitamin-020l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "325"
    },
    fields: {
      name: "Cappy apple 0.20l",
      code: "513",
      slug: "cappy-apple-020l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "338"
    },
    fields: {
      name: "Nesycená voda",
      code: "515",
      slug: "nesycena-voda",
      idCategory: "22",
      price: "20,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "340"
    },
    fields: {
      name: "Bonaqua",
      code: "514",
      slug: "bonaqua",
      idCategory: "22",
      price: "39,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "253"
    },
    fields: {
      name: "Broskev Limo",
      code: "600",
      slug: "broskev-limo",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "254"
    },
    fields: {
      name: "Malina Limo",
      code: "650",
      slug: "malina-limo",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "255"
    },
    fields: {
      name: "Domácí Ice tea 100%",
      code: "651",
      slug: "domaci-ice-tea-100",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "256"
    },
    fields: {
      name: "Jahoda Limo",
      code: "652",
      slug: "jahoda-limo",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "257"
    },
    fields: {
      name: "Zázvor Limo",
      code: "653",
      slug: "zazvor-limo",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "258"
    },
    fields: {
      name: "Borůvka Limo",
      code: "654",
      slug: "boruvka-limo",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "303"
    },
    fields: {
      name: "Aloe Vera Chia",
      code: "599",
      slug: "aloe-vera-chia",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "391"
    },
    fields: {
      name: "Džus s vodou",
      code: "620",
      slug: "dzus-s-vodou",
      idCategory: "22",
      price: "49,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "259"
    },
    fields: {
      name: "Broskev Mojito nealko",
      code: "655",
      slug: "broskev-mojito-nealko",
      idCategory: "22",
      price: "89,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "260"
    },
    fields: {
      name: "Malina Mojito nealko",
      code: "656",
      slug: "malina-mojito-nealko",
      idCategory: "22",
      price: "89,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "261"
    },
    fields: {
      name: "Citronová tráva Mojito nealko",
      code: "657",
      slug: "citronova-trava-mojito-nealko",
      idCategory: "22",
      price: "89,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "262"
    },
    fields: {
      name: "Jahoda Mojito nealko",
      code: "658",
      slug: "jahoda-mojito-nealko",
      idCategory: "22",
      price: "89,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "263"
    },
    fields: {
      name: "Zázvor Mojito nealko",
      code: "659",
      slug: "zazvor-mojito-nealko",
      idCategory: "22",
      price: "89,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "264"
    },
    fields: {
      name: "Borůvka Mojito nealko",
      code: "670",
      slug: "boruvka-mojito-nealko",
      idCategory: "22",
      price: "89,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "265"
    },
    fields: {
      name: "Zelený čaj",
      code: "700",
      slug: "zeleny-caj",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "266"
    },
    fields: {
      name: "Jamínový čaj",
      code: "701",
      slug: "jaminovy-caj",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "267"
    },
    fields: {
      name: "Zázvorový čaj",
      code: "702",
      slug: "zazvorovy-caj",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "268"
    },
    fields: {
      name: "Mátový čaj",
      code: "703",
      slug: "matovy-caj",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "269"
    },
    fields: {
      name: "Lotosový čaj",
      code: "704",
      slug: "lotosovy-caj",
      idCategory: "22",
      price: "49,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "270"
    },
    fields: {
      name: "Čaj z citronové trávy",
      code: "705",
      slug: "caj-z-citronove-travy",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "271"
    },
    fields: {
      name: "Espresso",
      code: "750",
      slug: "espresso",
      idCategory: "22",
      price: "49,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "272"
    },
    fields: {
      name: "Latte macchiato",
      code: "751",
      slug: "latte-macchiato",
      idCategory: "22",
      price: "79,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "273"
    },
    fields: {
      name: "Cappuccino",
      code: "752",
      slug: "cappuccino",
      idCategory: "22",
      price: "69,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "274"
    },
    fields: {
      name: "Café Vietnam teplá/studená",
      code: "753",
      slug: "cafe-vietnam-teplastudena",
      idCategory: "22",
      price: "89,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "275"
    },
    fields: {
      name: "Mr. Le Sprizz",
      code: "800",
      slug: "mr-le-sprizz",
      idCategory: "22",
      price: "139,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "276"
    },
    fields: {
      name: "Hugo",
      code: "801",
      slug: "hugo",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "277"
    },
    fields: {
      name: "Aperol Sprizz",
      code: "802",
      slug: "aperol-sprizz",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "278"
    },
    fields: {
      name: "Vento Sprizz",
      code: "803",
      slug: "vento-sprizz",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "279"
    },
    fields: {
      name: "Venezia Sprizz",
      code: "804",
      slug: "venezia-sprizz",
      idCategory: "22",
      price: "139,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "280"
    },
    fields: {
      name: "Aperol Crush",
      code: "805",
      slug: "aperol-crush",
      idCategory: "22",
      price: "139,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "281"
    },
    fields: {
      name: "Canpari Orange",
      code: "806",
      slug: "canpari-orange",
      idCategory: "22",
      price: "139,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "282"
    },
    fields: {
      name: "Aperol Orange",
      code: "807",
      slug: "aperol-orange",
      idCategory: "22",
      price: "139,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "283"
    },
    fields: {
      name: "Mojito Classic alko",
      code: "820",
      slug: "mojito-classic-alko",
      idCategory: "22",
      price: "119,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "284"
    },
    fields: {
      name: "Mojito Malina alko",
      code: "821",
      slug: "mojito-malina-alko",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "285"
    },
    fields: {
      name: "Mojito Zázvor alko",
      code: "822",
      slug: "mojito-zazvor-alko",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "286"
    },
    fields: {
      name: "Mojito Jahoda alko",
      code: "823",
      slug: "mojito-jahoda-alko",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "287"
    },
    fields: {
      name: "Mojito Kumquat a Citronová tráva alko",
      code: "824",
      slug: "mojito-kumquat-a-citronova-trava-alko",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "288"
    },
    fields: {
      name: "Mojito Citronová tráva alko",
      code: "825",
      slug: "mojito-citronova-trava-alko",
      idCategory: "22",
      price: "129,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "289"
    },
    fields: {
      name: "Pilsner Urquell 0.30l",
      code: "850",
      slug: "pilsner-urquell-030l",
      idCategory: "22",
      price: "35,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "290"
    },
    fields: {
      name: "Kozel 0.30l",
      code: "851",
      slug: "kozel-030l",
      idCategory: "22",
      price: "29,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "291"
    },
    fields: {
      name: "Pilsner Urquell 0.50l",
      code: "852",
      slug: "pilsner-urquell-050l",
      idCategory: "22",
      price: "45,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "292"
    },
    fields: {
      name: "Kozel 0.50l",
      code: "853",
      slug: "kozel-050l",
      idCategory: "22",
      price: "40,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "293"
    },
    fields: {
      name: "Saigon lahvové 0.5l",
      code: "854",
      slug: "saigon-lahvove-05l",
      idCategory: "22",
      price: "60,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "298"
    },
    fields: {
      name: "Birrel",
      code: "856",
      slug: "birrel",
      idCategory: "22",
      price: "40,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "294"
    },
    fields: {
      name: "Becherovka 2cl",
      code: "900",
      slug: "becherovka-2cl",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "295"
    },
    fields: {
      name: "Vodka 2cl",
      code: "901",
      slug: "vodka-2cl",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "296"
    },
    fields: {
      name: "Sake",
      code: "902",
      slug: "sake",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "297"
    },
    fields: {
      name: "Nep moi Vietnam",
      code: "903",
      slug: "nep-moi-vietnam",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "Tradiční rýžová vodka z Vietnamu se specifickou chutí Asie",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "307"
    },
    fields: {
      name: "Božkov Republica Reserva 40%",
      code: "904",
      slug: "bozkov-republica-reserva-40",
      idCategory: "22",
      price: "30,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "308"
    },
    fields: {
      name: "Gin",
      code: "905",
      slug: "gin",
      idCategory: "22",
      price: "20,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "309"
    },
    fields: {
      name: "Jack Daniels",
      code: "906",
      slug: "jack-daniels",
      idCategory: "22",
      price: "34,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "310"
    },
    fields: {
      name: "Scottish Stag",
      code: "907",
      slug: "scottish-stag",
      idCategory: "22",
      price: "20,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "311"
    },
    fields: {
      name: "Jim Beam",
      code: "908",
      slug: "jim-beam",
      idCategory: "22",
      price: "29,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "312"
    },
    fields: {
      name: "J.Walker Select 10Y",
      code: "909",
      slug: "jwalker-select-10y",
      idCategory: "22",
      price: "39,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "313"
    },
    fields: {
      name: "J.P. Chenet Reserve Imperiale",
      code: "910",
      slug: "jp-chenet-reserve-imperiale",
      idCategory: "22",
      price: "30,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "314"
    },
    fields: {
      name: "Vecchia Romagna Etichetta Nera",
      code: "911",
      slug: "vecchia-romagna-etichetta-nera",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "315"
    },
    fields: {
      name: "Barcelo Imperial",
      code: "912",
      slug: "barcelo-imperial",
      idCategory: "22",
      price: "55,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "316"
    },
    fields: {
      name: "Jägermeister",
      code: "913",
      slug: "jgermeister",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "317"
    },
    fields: {
      name: "Stará myslivecká",
      code: "914",
      slug: "stara-myslivecka",
      idCategory: "22",
      price: "20,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "318"
    },
    fields: {
      name: "Red Label",
      code: "915",
      slug: "red-label",
      idCategory: "22",
      price: "20,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "319"
    },
    fields: {
      name: "Rémy Martin 1738",
      code: "916",
      slug: "remy-martin-1738",
      idCategory: "22",
      price: "105,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "320"
    },
    fields: {
      name: "Chivas Regal",
      code: "917",
      slug: "chivas-regal",
      idCategory: "22",
      price: "99,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "321"
    },
    fields: {
      name: "Hennesy",
      code: "918",
      slug: "hennesy",
      idCategory: "22",
      price: "49,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "334"
    },
    fields: {
      name: "Božkov Vodka",
      code: "919",
      slug: "bozkov-vodka",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "335"
    },
    fields: {
      name: "Captain Morgan White Rum",
      code: "920",
      slug: "captain-morgan-white-rum",
      idCategory: "22",
      price: "25,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "301"
    },
    fields: {
      name: "Bílé víno Veltlínské zelené",
      code: "950",
      slug: "bile-vino-veltlinske-zelene",
      idCategory: "22",
      price: "49,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "302"
    },
    fields: {
      name: "Bílé víno Tramín červený pozdní sběr",
      code: "960",
      slug: "bile-vino-tramin-cerveny-pozdni-sber",
      idCategory: "22",
      price: "49,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "304"
    },
    fields: {
      name: "Bílé víno rozlévané",
      code: "951",
      slug: "bile-vino-rozlevane",
      idCategory: "22",
      price: "29,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "305"
    },
    fields: {
      name: "Červené víno rozlévané",
      code: "961",
      slug: "cervene-vino-rozlevane",
      idCategory: "22",
      price: "29,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "39"
    },
    fields: {
      name: "Phong Tom",
      code: "15",
      slug: "phong-tom",
      idCategory: "31",
      price: "40,00",
      isHot: 0,
      description: "Krevetové chipsy",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "331"
    },
    fields: {
      name: "Kečup",
      code: "200",
      slug: "kecup",
      idCategory: "31",
      price: "10,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "333"
    },
    fields: {
      name: "Tatarská omáčka",
      code: "201",
      slug: "tatarska-omacka",
      idCategory: "31",
      price: "10,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "327"
    },
    fields: {
      name: "Sushi Box",
      code: "S100",
      slug: "sushi-box",
      idCategory: "32",
      price: "79,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "328"
    },
    fields: {
      name: "Krabice",
      code: "101",
      slug: "krabice",
      idCategory: "32",
      price: "6,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "329"
    },
    fields: {
      name: "Taška",
      code: "102",
      slug: "taska",
      idCategory: "32",
      price: "1,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "376"
    },
    fields: {
      name: "Krabice 10Kc",
      code: "205",
      slug: "krabice-10kc",
      idCategory: "32",
      price: "10,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "377"
    },
    fields: {
      name: "Krabice 20Kc",
      code: "206",
      slug: "krabice-20kc",
      idCategory: "32",
      price: "20,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "344"
    },
    fields: {
      name: "EET10Percent",
      code: "EET10P",
      slug: "eet10percent",
      idCategory: "33",
      price: "1,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "345"
    },
    fields: {
      name: "EET15Percent",
      code: "EET15P",
      slug: "eet15percent",
      idCategory: "33",
      price: "1,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
  {
    sys: {
      id: "346"
    },
    fields: {
      name: "EET21Percent",
      code: "EET21P",
      slug: "eet21percent",
      idCategory: "33",
      price: "1,00",
      isHot: 0,
      description: "",
      image: {
        fields: {
          file: {
            url: ""
          }
        }
      }
    }
  },
];